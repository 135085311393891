import React from 'react';
import './AR.scss';
import TvImage from './../../images/Samsung_Lens.jpg';
import arIosBatch from './../../images/ARKit-Badge.svg';
import arIosFile from './../../arfiles/lens_bake3.usdz';
import arAndroidFile from './../../arfiles/lens_bake3.gltf';

class AR extends React.PureComponent {
	constructor(props) {
    super(props);
    this.state = {
      os: null
    }
  }

  componentDidMount() {
    const osType = this.getMobileOperatingSystem();
    this.setState({
      os: osType
    });
  }

  render() {
    const os = this.state.os;
    return (
      <section className="AR__container">
        <img className="thumbnail" src={TvImage} alt="AR" />
        {os === 'iOS' &&
        <a rel="ar" href={arIosFile}>
          <span className="batch"><img src={arIosBatch} alt="AR-Icon" /></span>
        </a>
        }
        {os === 'Android' &&
        <React.Fragment>
        <a rel="ar" href={arAndroidFile + "#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;"}>
          <span className="batch"><img src={arIosBatch} alt="AR-Icon" /></span>
        </a>
          <model-viewer src={arAndroidFile}
            alt="this is a 3d model">
          </model-viewer>
        </React.Fragment>
        }
      </section>
    );
  }

  getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
      }
      if (/android/i.test(userAgent)) {
          return "Android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }
      return "unknown";
  }
}

export default AR;
